<template>
  <popup
    v-model="dataShowPopup"
    position="bottom"
    :style="{ height: '90vh' }"
    class="call-mobile-popup"
    closeable
    round
    @closed="() => this.$emit('input', false)">
    <div class="mobile-modal">
      <div class="mobile-modal__title">
        Edit Issue
      </div>
      <div class="mobile-modal__body">
        <div>
          <div
            :class="['call-select-modal__wrapper call-input call-select-modal__wrapper--template', $v.dataCategoryId.$error && 'error-valide']">
            <v-select
              v-model="$v.dataCategoryId.$model"
              :options="dataSelectOptionsIssuesCategories"
              label="name"
              :components="{Deselect: dataComponentDeselect}"
              class="call-select-modal call-select">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
              <template
                slot="option"
                slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <label>Category</label>
          </div>
          <div
            :class="['call-select-modal__wrapper call-input call-select-modal__wrapper--template', $v.dataForm.issue_id.$error && 'error-valide']">
            <v-select
              v-model="$v.dataForm.issue_id.$model"
              :options="dataSelectOptionsIssues"
              label="name"
              :components="{Deselect: dataComponentDeselect}"
              class="call-select-modal call-select">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
              <template
                slot="option"
                slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <label>Issue</label>
          </div>
          <div
            class="sms-template">
            {{ dataForm.issues_id | get('description') }}
          </div>
          <div :class="['call-select-modal__wrapper call-input', $v.dataForm.comment.$error && 'error-valide']">
            <b-form-textarea
              v-model="$v.dataForm.comment.$model"
              placeholder="Your comment..."
              rows="3"
              max-rows="6" />
          </div>
        </div>
        <div class="mobile-date-bottom">
          <call-button
            type="success"
            full
            :disabled="$v.$invalid"
            :icon="dataIconCheck"
            @click="clickAddIssue()">
            &nbsp;Update Issue
          </call-button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { Notify, Popup } from 'vant';
import { CALL_UPDATE, ISSUES_GET, ISSUES_GET_CATEGORIES } from '../../../store/types/actions.types';
import CallButton from '../../common/CallButton';
import { faCheck, faSms } from '@fortawesome/free-solid-svg-icons';
import selectSmsTemplate from '@/mixins/select/selectSmsTemplate';
import { BFormTextarea } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import selectIssuesCategories from '@/mixins/select/selectIssuesCategories';
import helper from '@/service/helper';
import { maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'CallMobileEditPhoneIssue',
  components: {
    Popup,
    CallButton,
    BFormTextarea
  },
  mixins: [selectSmsTemplate, selectIssuesCategories, validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    teamListId: {
      type: Array,
      default: () => ([])
    },
    objIssues: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconSms: faSms,
      dataShowPopup: this.value,
      dataSelectOptionsIssues: [],
      dataCategoryId: {},
      dataWatch: null,
      dataForm: {
        comment: null,
        issue_id: {}
      }
    };
  },
  validations: {
    dataCategoryId: {
      required: function (val) {
        return val != null && Object.keys(val).length > 0;
      }
    },
    dataForm: {
      issue_id: {
        required: function (val) {
          return val != null && Object.keys(val).length > 0;
        }
      },
      comment: {
        maxLength: maxLength(255)
      }
    }
  },
  watch: {
    async value () {
      this.dataShowPopup = this.value;
      if (this.value) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const [issuesCategories = [], { payload: { list = [] } }] = await Promise.all([
          this.$store.dispatch(`issues/${ISSUES_GET_CATEGORIES}`, {}),
          this.$store.dispatch(`issues/${ISSUES_GET}`, {
            team_ids: this.teamListId
          })
        ]);

        const issueData = list.find(item => item.id === this.objIssues.issue_id);

        this.dataCategoryId = issuesCategories.find(item => +item.id === +issueData.category_id);

        this.dataForm.issue_id = issueData;
        this.dataForm.comment = this.objIssues.comment;

        this.dataSelectOptionsIssuesCategories = issuesCategories;
        this.dataSelectOptionsIssues = list;

        this.dataWatch = this.$watch('dataCategoryId', _ => {
          this.dataForm.issue_id = {};
          this.$_ajaxIssues();
        });
      } else {
        this.dataWatch();
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async $_ajaxIssues () {
      const { payload: { list = [] } } = await this.$store.dispatch(`issues/${ISSUES_GET}`, {
        params: {
          category_ids: [this.dataCategoryId?.id],
          team_ids: this.teamListId
        }
      });

      this.dataSelectOptionsIssues = list;
    },
    async clickAddIssue () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`call/${CALL_UPDATE}`, {
          params: helper.clearObject({
            issue_id: this.dataForm.issue_id?.id ?? null,
            issue_comment: this.dataForm.comment
          }),
          callId: this.objIssues.call_id
        });

        this.$emit('on-update');
        Notify({ type: 'success', message: 'Issue updated successfully' });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
