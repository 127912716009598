<template>
  <popup
    v-model="dataShowPopup"
    :style="{ maxHeight: 'calc(100vh - 200px)' }"
    position="bottom"
    class="call-mobile-popup"
    closeable
    round
    @closed="() => this.$emit('input', false)">
    <div class="mobile-modal">
      <div class="mobile-modal__title">
        Send Gift
      </div>
      <div class="mobile-modal__body">
        <div>
          <div
            v-for="list in [...computedStoreBonusesList]"
            :key="list.id"
            :class="{'is-active': list.id === dataForm.bonus_id}"
            class="call-phone-checkbox-filter">
            <input
              :id="'subscribeNews' + list.id"
              v-model="$v.dataForm.bonus_id.$model"
              :value="list.id"
              type="radio">
            <label
              class="call-phone-checkbox-filter__title"
              :for="'subscribeNews' + list.id">
              {{ list.description }}
            </label>
          </div>
        </div>
        <div>
          <div class="mobile-modal__button-item">
            <call-button
              type="danger"
              plain
              full
              :icon="dataIconClose"
              @click="clickCancel()">
              Cancel
            </call-button>
            <call-button
              type="success"
              full
              :disabled="$v.$invalid"
              :icon="dataIconCheck"
              @click="clickSendBonus()">
              &nbsp;Send Gift
            </call-button>
          </div>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { Notify, Popup } from 'vant';
import CallButton from '../../common/CallButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';
import { BONUS_ADD_PLAYER, GET_BONUS_LIST_PROJECT } from '../../../store/types/actions.types';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'CallMobileGifts',
  components: {
    Popup,
    CallButton
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconClose: faTimes,
      dataShowPopup: this.value,
      dataForm: {
        bonus_id: null
      }
    };
  },
  validations: {
    dataForm: {
      bonus_id: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      computedStoreBonusesList: 'bonus/bonusesListProject'
    }),
    computedProjectId () {
      return this.$route.params?.projectId;
    }
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
      if (this.value) {
        this.$store.dispatch(`bonus/${GET_BONUS_LIST_PROJECT}`, {
          projectId: this.computedProjectId
        });
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickSendBonus () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`bonus/${BONUS_ADD_PLAYER}`, {
          taskId: this.taskId,
          params: {
            ...this.dataForm
          },
          context: this
        });
        this.$emit('update');
        Notify({ type: 'success', message: 'Bonus has been successfully issued' });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    },
    async clickCancel () {
      this.$emit('update');
      this.onCloseModal();
    }
  }
};
</script>
