import { Notify, Popup } from 'vant';
import { TASK_CLOSE } from '../../../store/types/actions.types';
import CallButton from '../../common/CallButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { BFormTextarea } from 'bootstrap-vue';

export default {
  name: 'CallMobileCloseTask',
  components: {
    Popup,
    CallButton,
    BFormTextarea
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataShowPopup: this.value,
      dataForm: {
        comment: null
      }
    };
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
    }
  },
  validations: {
    dataForm: {
      comment: {
        required
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickCloseTask () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`task/${TASK_CLOSE}`, {
          taskId: this.taskId,
          params: {
            ...this.dataForm
          },
          context: this
        });
        this.$emit('on-update');
        Notify({ type: 'success', message: 'Successfully closed' });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  },
  render (h) {
    return (
      <popup vModel={this.dataShowPopup} position="bottom" style={{ height: '90%' }} class="call-mobile-popup" closeable round onClosed={() => this.$emit('input', false)}>
        <div class="mobile-modal">
          <div class="mobile-modal__title">
            Close Task
          </div>
          <div class="mobile-modal__body">
            <div class="call-select-modal__wrapper call-input">
              <b-form-textarea
                vModel={this.$v.dataForm.comment.$model}
                placeholder="Your comment..."
                rows="3"
                max-rows="6"/>
            </div>
            <div class="mobile-date-bottom">
              <call-button type="success" full icon={this.dataIconCheck} onClick={() => this.clickCloseTask()}>
                &nbsp;Close Task
              </call-button>
            </div>
          </div>
        </div>
      </popup>
    );
  }
};
