import { Picker, Popup } from 'vant';

import dayjs from 'dayjs';

export default {
  name: 'CallMobileDateTime',
  components: {
    Popup,
    Picker
  },
  props: {
    time: {
      type: Date,
      default: new Date()
    }
  },
  data () {
    return {
      dataCurrentDate: new Date()
    };
  },
  mounted () {
    this.dataCurrentDate = this.time;
  },
  methods: {
    onConfirm (index, value) {
      // this.dataCurrentDate = new Date(new Date(value[0].values).setHours(value[1].values, value[2].values, 0));
      this.$emit('on-date', new Date(new Date(value[0].values).setHours(value[1].values, value[2].values, 0)));
    }
  },
  computed: {
    computedColumns () {
      const _day = new Date().getDate();
      return [
        {
          values: [...new Array(180)].map((item, index) => {
            index += _day;
            return {
              values: new Date(new Date().setDate(index)),
              text: dayjs(new Date(new Date().setDate(index))).format('ddd DD MMM')
            };
          }),
          defaultIndex: this.dataCurrentDate.getDate() - _day
        },
        {
          values: [...new Array(24)].map((item, index) => {
            return {
              values: index,
              text: (index > 9 ? index : '0' + index) + ' hour'
            };
          }),
          defaultIndex: this.dataCurrentDate.getHours()
        },
        {
          values: [...new Array(60)].map((item, index) => {
            return {
              values: index,
              text: (index > 9 ? index : '0' + index) + ' minutes'
            };
          }),
          defaultIndex: this.dataCurrentDate.getMinutes()
        }
      ];
    }
  },
  render (h) {
    return (
      <picker
        item-height={34}
        columns={this.computedColumns}
        onChange={(value, index) => this.onConfirm(value, index) }
      />
    );
  }
};
