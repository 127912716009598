import {
  faCheck,
  faClock,
  faComment, faGift,
  faPen,
  faPhone,
  faSms,
  faTrashAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import CallButton from './CallButton';
import { mapGetters } from 'vuex';
import helper from '../../service/helper';

export default {
  name: 'CallTimeLine',
  components: { CallButton },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsOperator: 'auth/isOperator',
      computedCurrentUser: 'auth/currentUser'
    })
  },
  data () {
    return {
      dataIconClock: faClock,
      dataIconPhone: faPhone,
      dataIconComment: faComment,
      dataIconCheck: faCheck,
      dataIconUser: faUser,
      dataIconPen: faPen,
      dataIconTrash: faTrashAlt,
      dataIconSms: faSms,
      dataIconGift: faGift
    };
  },
  render (h) {
    const _icon = ({
      type,
      status
    }) => {
      switch (type) {
        case 'outcoming_call':
          if (status === 'success') {
            return this.dataIconPhone;
          } else if (['not_available', 'invalid_number', 'cancelled'].includes(status)) {
            return this.dataIconPhone;
          } else if (status === 'noanswer') {
            return this.dataIconPhone;
          } else if (status === 'rejected') {
            return this.dataIconPhone;
          }
          break;
        case 'issue_comment':
          return this.dataIconComment;
        case 'comment':
          return this.dataIconComment;
        case 'closing':
          return this.dataIconCheck;
        case 'set_execute_before':
          return this.dataIconClock;
        case 'set_operator':
          return this.dataIconUser;
        case 'sms':
          return this.dataIconSms;
        case 'set_bonus':
          return this.dataIconGift;
        case 'incoming_call':
          return '/img/incoming-outline.svg';
      }
    };

    const _iconColor = ({
      type,
      status
    }) => {
      switch (type) {
        case 'outcoming_call':
          if (status === 'success') {
            return 'success';
          } else if (['not_available', 'invalid_number', 'cancelled'].includes(status)) {
            return 'danger';
          } else if (status === 'noanswer') {
            return 'warning';
          } else if (status === 'rejected') {
            return 'warning';
          }
          break;
        case 'incoming_call':
        case 'comment':
        case 'closing':
        case 'set_operator':
        case 'set_bonus':
        case 'issue_comment':
          return 'success';
        case 'set_execute_before':
          return 'warning';
        case 'sms':
          if (status === 'success') {
            return 'success';
          } else if (status === 'error') {
            return 'danger';
          } else if (status === 'new') {
            return 'warning';
          }
      }
    };

    const _textCall = (data) => {
      const {
        type,
        status,
        duration,
        operator_name: operatorName,
        updated_at: update,
        scheduled_at: scheduledAt,
        template_name = '',
        feedback = '',
        description = '',
        comment = '',
        issue_name: issueName = ''
      } = data;
      switch (type) {
        case 'outcoming_call':
          if (status === 'success') {
            return [
              <p>
                Success Call, {this.$options.filters.dateCall(duration)}
              </p>,
              <p>{feedback}</p>,
              !issueName && <CallButton
                  type="warning"
                  plain
                  size="min"
                  onClick={() => this.$emit('on-add-issues', data)}
                  icon={this.dataIconComment}
              >
                 Add Issue
            </CallButton>
            ];
          } else if (status === 'cancelled') {
            return <p>
              Cancelled Call
            </p>;
          } else if (status === 'noanswer') {
            return <p>
              Call No Answer for {this.$options.filters.dateFilter(update, 'HH:mm')}
            </p>;
          } else if (status === 'rejected') {
            return <p>
              Call Rejected
            </p>;
          } else if (status === 'invalid_number') {
            return <p>
              Call Invalid Number
            </p>;
          } else if (status === 'not_available') {
            return <p>
              Not Available
            </p>;
          }
          break;
        case 'incoming_call':
          if (status === 'success') {
            return [
              <p>
                Incoming Call, {this.$options.filters.dateCall(duration)}
              </p>,
              <p>{feedback}</p>
            ];
          }
          break;
        case 'set_execute_before':
          return (
            <p>
              Scheduled for {this.$options.filters.dateFilter(scheduledAt)}
            </p>
          );
        case 'set_operator':
          return (
            <p>
              Assigned to <strong>{operatorName}</strong>
            </p>
          );
        case 'sms':
          return (
            <p>
              <strong>SMS:</strong> {template_name}
            </p>
          );
        case 'set_bonus':
          return (
            <p>
              <strong>Bonus Given:</strong> {description}
            </p>
          );
        case 'closing':
          return (
            <p>
              <strong>Closed task</strong><br/> {comment}
            </p>
          );
        case 'issue_comment':
          return (
              <p>
                <strong>{issueName}</strong>
                <br/>
                {comment}
              </p>
          );
      }
    };

    const _textUserName = ({
      type,
      closed_by_name: closedByName = '',
      user_name: userName = '',
      operator_name: operatorName = '',
      status = ''
    }) => {
      switch (type) {
        case 'closing':
          return (
            <p class="timeline__name">
              <font-awesome-icon
                icon={this.dataIconUser}/>
              &nbsp;
              {closedByName}
            </p>
          );
        case 'set_operator':
          if (helper.isEmpty(userName)) {
            return;
          }
          return (
            <p class="timeline__name">
              <font-awesome-icon
                icon={this.dataIconUser}/>
              &nbsp;
              {userName}
            </p>
          );
        case 'sms':
          return (
            <p class={['timeline__name', 'sms', _iconColor({
              type,
              status
            })]}>
              &bull; {status === 'new' ? 'Sent' : status === 'success' ? 'Delivered' : status === 'error' ? 'Wasn’t Sent' : 'Wasn’t Sent'}
            </p>
          );
        case 'issue_comment':
          return (
              <p class="timeline__name">
                Issue
              </p>
          );
        default:
          if (helper.isEmpty(operatorName) && helper.isEmpty(userName)) {
            return;
          }
          return (
            <p class="timeline__name">
              <font-awesome-icon
                icon={this.dataIconUser}/>
              &nbsp;
              {(operatorName || userName) || ''}
            </p>
          );
      }
    };

    const _disabledEditComment = ({ created_at: created, type }) => {
      if (type === 'issue_comment') {
        return true;
      }
      return (new Date(created).getTime() + (3600000 * (-(new Date().getTimezoneOffset() / 60)).toFixed(0)) + 600000) > Date.now();
    };

    return (
      <div class="timeline__wrapper">
        {
          this.events.map(item => {
            return (
              <div>
                <div class="timeline__item">
                  {item.type === 'incoming_call' ? <span class="timeline__icon">
                    <img style="width:14px;margin-top: -2px;" src={_icon(item)}/>
                  </span>
                    : <span class={'timeline__icon ' + _iconColor(item)}>
                    <font-awesome-icon
                      icon={_icon(item)}/>
                  </span>}
                  <div class="timeline__head">
                    {item.created_at && <p class="timeline__date">
                      {this.$options.filters.dateFilter(item.created_at)}
                    </p>
                    }
                    {
                      _textUserName(item)
                    }
                  </div>
                  <div class="timeline__body">
                    {item.type === 'outcoming_call'
                      ? _textCall(item) : ~['comment'].indexOf(item.type)
                        ? <p>
                          {item?.comment || ''}
                        </p> : _textCall(item)
                    }
                    {
                      (this.computedIsAdmin || this.computedCurrentUser.id === item.user_id) && (item.type === 'comment' || item.type === 'issue_comment') && _disabledEditComment(item) ? [
                        this.computedCurrentUser.id === item.user_id || this.computedIsAdmin
                          ? <CallButton outlet notBorder size="mini" type="success" icon={this.dataIconPen}
                                        onClick={() => this.$emit('on-comment', {
                                          ...(item.type === 'comment' && {
                                            id: item.id,
                                            comment: item.comment
                                          }) || {},
                                          ...(item.type === 'issue_comment' && item) || {},
                                          type: 'edit'
                                        })}> Edit </CallButton> : null,
                        this.computedIsAdmin
                          ? <CallButton outlet notBorder size="mini" type="danger" icon={this.dataIconTrash}
                                        onClick={() => this.$emit('on-comment', {
                                          ...(item.type === 'comment' && {
                                            id: item.id
                                          }) || {},
                                          ...(item.type === 'issue_comment' && item) || {},
                                          type: 'delete'
                                        })}> Delete </CallButton> : null
                      ] : null
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
};
