<template>
  <popup
    v-model="dataShowPopup"
    position="bottom"
    :style="{ height: '90vh' }"
    class="call-mobile-popup"
    closeable
    round
    @closed="() => this.$emit('input', false)">
    <div class="mobile-modal">
      <div class="mobile-modal__title">
        Send SMS
      </div>
      <div class="mobile-modal__body">
        <div>
          <div
            :class="['call-select-modal__wrapper', 'call-input', 'call-select-modal__wrapper--template',
                     !dataSelectSmsTemplateValue ? 'call-select--empty' : null]">
            <v-select
              v-model="dataSelectSmsTemplateValue"
              :options="dataSelectOptionsSmsTemplate"
              :searchable="false"
              label="name"
              placeholder="Template"
              :class="['call-select', dataSelectSmsTemplateValue ? 'call-select--value' : null]"
              :components="{ Deselect: dataComponentDeselect }">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
            </v-select>
            <label>Template</label>
          </div>
          <p
            v-if="dataSelectSmsTemplateValue"
            class="sms-template__title">
            <font-awesome-icon
              class="success"
              :icon="dataIconSms" /> {{ dataSelectSmsTemplateValue | get('name') }}
          </p>
          <div
            class="sms-template"
            v-html="computedBody" />
        </div>
        <div class="mobile-date-bottom">
          <call-button
            type="success"
            full
            :disabled="computedIsValide"
            :icon="dataIconCheck"
            @click="clickSmsSend()">
            &nbsp;Send SMS
          </call-button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { Dialog, Notify, Popup } from 'vant';
import { SMS_SEND, SMS_TEMPLATE_GET_ALL } from '../../../store/types/actions.types';
import CallButton from '../../common/CallButton';
import { faCheck, faSms } from '@fortawesome/free-solid-svg-icons';
import selectSmsTemplate from '@/mixins/select/selectSmsTemplate';
import helper from '../../../service/helper';

export default {
  name: 'CallMobileSmsTemplate',
  components: {
    Popup,
    CallButton
  },
  mixins: [selectSmsTemplate],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    playerId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconSms: faSms,
      dataShowPopup: this.value
    };
  },
  computed: {
    computedBody () {
      return this.dataSelectSmsTemplateValue?.body?.replace(/\n/g, '<br/>');
    },
    computedIsValide () {
      return helper.isEmpty(this.dataSelectSmsTemplateValue);
    }
  },
  watch: {
    async value () {
      this.dataShowPopup = this.value;
      if (this.value) {
        const { payload: { list = [] } } = await this.$store.dispatch(`sms/${SMS_TEMPLATE_GET_ALL}`, {
          params: {
            limit: 100,
            page: 0
          }
        });
        this.dataSelectOptionsSmsTemplate = list;
        if (!helper.isEmpty(this.dataSelectOptionsSmsTemplate)) {
          this.dataSelectSmsTemplateValue = helper.getOneArray(this.dataSelectOptionsSmsTemplate);
        }
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickSmsSend () {
      Dialog.confirm({
        title: 'Confirm actions',
        message: `Are you sure you want to send SMS to ${this.playerId}?`
      })
        .then(async () => {
          try {
            await this.$store.dispatch(`sms/${SMS_SEND}`, {
              taskId: this.taskId,
              params: {
                template_id: this.dataSelectSmsTemplateValue?.id
              },
              context: this
            });
            this.$emit('on-update');
            Notify({ type: 'success', message: 'SMS sent successfully' });
            this.onCloseModal();
          } catch (e) {
            throw Error(e);
          }
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
