import { Popup } from 'vant';
import CallButton from '../../common/CallButton';

export default {
  name: 'CallMobileReScheduleOptions',
  components: {
    Popup,
    CallButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataShowPopup: this.value
    };
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    }
  },
  render (h) {
    return (
      <popup vModel={this.dataShowPopup} position="bottom" style={{ height: '350px' }} class="call-mobile-popup" closeable round onClosed={() => this.$emit('input', false)}>
        <div class="mobile-modal">
          <div class="mobile-modal__title">
            Schedule Call
          </div>
          <div class="mobile-modal__body">
            <div class="mobile-modal__options-re-schedule">
              <call-button type="success" size="big" full plain onClick={() => this.$emit('on-options', 'tomorrow')}>Tomorrow</call-button>
              <call-button type="success" size="big" full plain onClick={() => this.$emit('on-options', 'the_day_after_tomorrow')}>The Day After Tomorrow</call-button>
              <call-button type="success" size="big" full plain onClick={() => this.$emit('on-options', 'after_one_week')}>After One Week</call-button>
              <call-button type="warning" size="big" full plain onClick={() => this.$emit('on-options', 'manually')}>Chose Manually</call-button>
            </div>
          </div>
        </div>
      </popup>
    );
  }
};
