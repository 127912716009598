<template>
  <popup
    v-model="dataShowPopup"
    position="bottom"
    :style="{ height: '600px' }"
    class="call-mobile-popup"
    round
    :close-on-click-overlay="false"
    @closed="() => this.$emit('input', false)">
    <div class="mobile-modal">
      <div class="mobile-modal__title">
        Call Results
      </div>
      <div class="mobile-modal__body">
        <div class="switcher-wrapper">
          <div class="switcher-item">
            <input
              id="radio-1"
              v-model="dataModelChecked"
              value="success"
              type="radio"
              name="radio">
            <label for="radio-1">Success</label>
          </div>
          <div class="switcher-item">
            <input
              id="radio-2"
              v-model="dataModelChecked"
              value="reject"
              type="radio"
              name="radio">
            <label for="radio-2">Failed</label>
          </div>
        </div>
        <div v-if="dataModelChecked === 'success'">
          <h3 style="font-size: 16px">
            Duration: {{ dataCurrentDate.getMinutes() }} Min {{ dataCurrentDate.getSeconds() }} Sec
          </h3>
          <picker
            :item-height="34"
            :columns="computedColumns"
            @change="onConfirm" />
          <h3 style="font-size: 16px">
            Feedback
          </h3>
          <div class="call-select-modal__wrapper call-input">
            <b-form-textarea
              v-model="$v.dataForm.feedback.$model"
              placeholder="Details of your call"
              rows="3"
              max-rows="6" />
          </div>
        </div>
        <div v-else>
          <div
            v-for="list in filterPhone"
            :key="list.name"
            :class="{'is-active': list.key === dataForm.status}"
            class="call-phone-checkbox-filter">
            <input
              :id="'subscribeNews' + list.name"
              v-model="$v.dataForm.status.$model"
              :value="list.key"
              type="radio">
            <label
              class="call-phone-checkbox-filter__title"
              :for="'subscribeNews' + list.name">
              {{ list.name }}
            </label>
          </div>
        </div>
        <div class="mobile-modal__button-item">
          <call-button
            type="success"
            full
            :disabled="$v.$invalid"
            :icon="dataIconCheck"
            @click="clickPhoneSubmit()">
            &nbsp;Submit
          </call-button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { Picker, Popup } from 'vant';
import CallButton from '../../common/CallButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import selectSmsTemplate from '@/mixins/select/selectSmsTemplate';
import { mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { BFormTextarea } from 'bootstrap-vue';
import { CALL_UPDATE } from '../../../store/types/actions.types';
import storage from '../../../service/storage';
import helper from '../../../service/helper';
import { SET_ALL } from '../../../store/types/mutations.types';

export default {
  name: 'CallMobileResultPhoneCall',
  components: {
    Popup,
    CallButton,
    Picker,
    BFormTextarea
  },
  mixins: [selectSmsTemplate, validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    callId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconClose: faTimes,
      dataShowPopup: this.value,
      dataModelChecked: 'success',
      dataCurrentDate: new Date(new Date().setMinutes(0, 0)),
      filterPhone: [
        {
          name: 'Rejected',
          key: 'rejected'
        },
        {
          name: 'No Answer',
          key: 'cancelled'
        },
        {
          name: 'Invalid Number',
          key: 'invalid_number'
        },
        {
          name: 'Not Available',
          key: 'not_available'
        }
      ],
      dataForm: {
        status: 'success',
        feedback: null,
        duration: null
      }
    };
  },
  validations: {
    dataForm: {
      status: {
        required
      },
      feedback: {
        required: requiredIf(function () {
          return this.dataModelChecked === 'success';
        })
      },
      duration: {
        required: requiredIf(function () {
          return this.dataModelChecked === 'success';
        }),
        isUnique: function (value) {
          if (this.dataModelChecked === 'reject') {
            return true;
          }
          return value > 0;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      computedStoreFilterPhone: 'mobile/filterPhone'
    }),
    computedColumns () {
      return [
        {
          values: [...new Array(60)].map((item, index) => {
            return {
              values: index,
              text: (index > 9 ? index : '0' + index) + ' Min'
            };
          }),
          defaultIndex: this.dataCurrentDate.getMinutes()
        },
        {
          values: [...new Array(60)].map((item, index) => {
            return {
              values: index,
              text: (index > 9 ? index : '0' + index) + ' Sec'
            };
          }),
          defaultIndex: this.dataCurrentDate.getSeconds()
        }
      ];
    }
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
      if (this.value) {
        this.dataCurrentDate = new Date(new Date().setMinutes(0, 0));
        this.dataForm = {
          status: 'success',
          feedback: null,
          duration: null
        };
        this.dataModelChecked = 'success';
      }
    }
  },
  methods: {
    onConfirm (picker, value, index) {
      this.dataCurrentDate = new Date(new Date(this.dataCurrentDate).setMinutes(value[0].values, value[1].values, 0));
      this.dataForm.duration = value[0].values * 60 + value[1].values;
    },
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickPhoneSubmit () {
      try {
        await this.$store.dispatch(`call/${CALL_UPDATE}`, {
          params: this.dataModelChecked === 'success' ? helper.clearObject({
            ...this.dataForm,
            status: 'success'
          }) : helper.clearObject({
            status: this.dataForm.status
          }),
          callId: this.callId
        });
        storage.remove('blocked_call');
        this.$store.commit(`mobile/${SET_ALL}`, { data: {}, key: 'blockedCall' });
        this.$emit('update');
        this.onCloseModal();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.mobile-body .call-mobile-popup .mobile-modal .mobile-modal__body
  justify-content: flex-start
h3
  font-size: 16px
  font-weight: 600
  color: #222222
.van-picker
  margin-bottom: 16px
.mobile-modal__button-item:last-of-type
  margin-top: auto
</style>
