<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Edit Comment"
    @close="onCloseModal">
    <div>
      <div
        class="call-select-modal__wrapper call-input">
        <b-form-textarea
          v-model="$v.dataForm.comment.$model"
          placeholder="Your comment..."
          rows="3"
          max-rows="6" />
      </div>
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        type="success"
        :disabled="$v.$invalid"
        @click="clickUpdateComment()">
        Update Comment
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import CallButton from '../CallButton';
import { BFormTextarea, BModal } from 'bootstrap-vue';
import iconsModal from '../../../mixins/iconsModal';
import { TASK_UPDATE_COMMENT } from '../../../store/types/actions.types';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Notify } from 'vant';

export default {
  name: 'CallEditComment',
  components: {
    CallButton,
    BModal,
    BFormTextarea
  },
  mixins: [validationMixin, iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    objComment: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        comment: null
      }
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
        this.$set(this.dataForm, 'comment', this.objComment.comment);
      }
    }
  },
  validations: {
    dataForm: {
      comment: {
        required
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickUpdateComment () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`task/${TASK_UPDATE_COMMENT}`, {
          taskId: this.taskId,
          commentId: this.objComment.id,
          params: {
            ...this.dataForm
          },
          context: this
        });
        this.$emit('on-update');
        if (this.$device.mobile) {
          Notify({ type: 'success', message: 'Comment successfully updated' });
        } else {
          this.$Notify({
            title: 'Comment',
            message: 'Successfully updated',
            type: 'success'
          });
        }
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
