import { Notify, Popup } from 'vant';
import CallMobileDateTime from './CallMobileDateTime';
import { TASKS_UPDATE } from '../../../store/types/actions.types';
import CallButton from '../../common/CallButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CallMobileReScheduleOptions from './CallMobileReScheduleOptions';

export default {
  name: 'CallMobileReSchedule',
  components: {
    Popup,
    CallMobileDateTime,
    CallMobileReScheduleOptions,
    CallButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    timeSchedule: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataShowPopup: this.value,
      dataShowDateTime: false,
      dataShowReScheduleOptions: false,
      dataIsShowTime: false,
      dataForm: {
        execute_before: null
      }
    };
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
      if (this.dataShowPopup) {
        this.dataForm.execute_before = new Date(new Date().setHours(new Date().getHours() + 24));
        this.dataIsShowTime = false;
      } else {
        setTimeout(_ => {
          this.dataIsShowTime = true;
        }, 100);
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickScheduleTask () {
      try {
        await this.$store.dispatch(`task/${TASKS_UPDATE}`, {
          taskId: this.taskId,
          params: {
            execute_before: new Date(Date.UTC(this.dataForm.execute_before.getUTCFullYear(), this.dataForm.execute_before.getUTCMonth(), this.dataForm.execute_before.getUTCDate(), this.dataForm.execute_before.getUTCHours(), this.dataForm.execute_before.getUTCMinutes(), 0))
          },
          context: this
        });
        this.$emit('on-update');
        Notify({ type: 'success', message: 'Task successfully updated' });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  },
  render (h) {
    return (
      <popup vModel={this.dataShowPopup} position="bottom" style={{ height: '380px' }} class="call-mobile-popup" closeable round onClosed={() => this.$emit('input', false)}>
        <div class="mobile-modal">
          <div class="mobile-modal__title">
            Schedule Call
          </div>
          <div class="mobile-modal__body">
            {!this.dataIsShowTime && <call-mobile-date-time on-on-date={(e) => { this.dataForm.execute_before = e; }} time={this.dataForm.execute_before} /> }
            <div class="mobile-date-bottom">
              <call-button type="success" full icon={this.dataIconCheck} onClick={() => this.clickScheduleTask()}>
                &nbsp;Schedule Call
              </call-button>
            </div>
          </div>
        </div>
      </popup>
    );
  }
};
