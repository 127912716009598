<template>
  <div>
    <pull-refresh
      v-model="dataIsLoading"
      @refresh="$_ajax">
      <div class="person-info__wrapper">
        <div
          v-if="computedProjectName"
          class="person-info__data">
          <p>
            <font-awesome-icon
              :icon="dataIconBriefcase" />
            Project:
          </p>
          <p> {{ computedProjectName || '' }}</p>
        </div>
        <div
          v-if="dataTaskDetail.player_name"
          class="person-info__data">
          <p>
            <font-awesome-icon
              :icon="dataIconUser" />
            Name
          </p>
          <p>{{ dataTaskDetail.player_name || '' }}</p>
        </div>

        <div
          v-if="dataTaskDetail.player_phone"
          class="person-info__data">
          <p>
            <font-awesome-icon
              :icon="dataIconPhone" />
            Phone
          </p>
          <p>{{ dataTaskDetail.player_phone || '' }}</p>
        </div>
        <div
          v-if="dataTaskDetail.player_birthday"
          class="person-info__data">
          <p>
            <font-awesome-icon
              :icon="dataIconGift" />Birthday
          </p>
          <p>{{ dataTaskDetail.player_birthday | dateBrithday }}</p>
        </div>
      </div>
      <call-mobile-task-card
        :obj-card="dataTaskDetail"
        comment-not />
      <call-time-line
        v-if="computedEvents"
        :events="dataTaskDetail.events"
        @on-add-issues="onAddIssue"
        @on-comment="onActionButtonComment" />
      <div
        v-else
        class="timeline__item--empty"
        style="margin: 12px 0px 12px 0px">
        <font-awesome-icon :icon="dataIconEmptyComment" /> No Activity Yet
      </div>
      <div v-if="dataTaskDetail.closed_by === null">
        <call-button
          v-if="!dataShowAddComment"
          type="success"
          plain
          full
          :icon="dataIconAddComment"
          @click="dataShowAddComment = true;">
          Add Comment
        </call-button>
        <div
          v-else
          class="comment__wrapper">
          <div
            class="call-select-modal__wrapper call-input">
            <b-form-textarea
              v-model="$v.dataForm.comment.$model"
              placeholder="Your comment..."
              rows="3"
              max-rows="6" />
          </div>
          <div class="comment__button-wrapper">
            <call-button
              type="danger"
              plain
              full
              :icon="dataIconClose"
              @click="dataShowAddComment = false;">
              Close
            </call-button>
            <call-button
              type="success"
              :disabled="$v.$invalid"
              plain
              :icon="dataIconCheck"
              full
              @click="clickAddComment()">
              Save
            </call-button>
          </div>
        </div>
      </div>
      <div
        v-if="!dataTaskDetail.closed_by && !dataShowAddComment"
        style="height: 150px" />
    </pull-refresh>
    <call-mobile-re-schedule
      v-model="dataShowAddSchedule"
      :time-schedule="dataTaskDetail.execute_before"
      :task-id="computedTaskId"
      @on-update="onGoTo" />
    <call-mobile-close-task
      v-model="dataShowCloseTask"
      :task-id="computedTaskId"
      @on-update="onCloseTask" />
    <call-delete-comment
      v-model="dataShowDeleteComment"
      :task-id="computedTaskId"
      :comment-id="dataPropsComment.id"
      @on-update="$_ajax" />
    <call-edit-comment
      v-model="dataShowEditComment"
      :task-id="computedTaskId"
      :obj-comment="dataPropsComment"
      @on-update="$_ajax" />
    <call-mobile-sms-template
      v-model="dataShowSmsTemplate"
      :player-id="dataTaskDetail.player_id"
      :task-id="computedTaskId"
      @on-update="$_ajax" />
    <call-mobile-result-phone-call
      v-model="dataShowResultCall"
      :call-id="computedCallIdSubmite"
      @update="onCallCustomSubmit" />
    <call-mobile-gifts
      v-model="dataShowGift"
      :task-id="computedTaskId"
      @on-update="$_ajax" />
    <call-mobile-add-phone-issue
      v-model="dataShowAddIssue"
      :call-id="dataPropsCallId"
      :team-list-id="computedTeamsIds"
      @on-update="$_ajax" />
    <call-mobile-edit-phone-issue
      v-model="dataShowEditIssue"
      :obj-issues="dataPropsEditIssue"
      :team-list-id="computedTeamsIds"
      @on-update="$_ajax" />

    <div
      v-if="!dataTaskDetail.closed_by && !dataShowAddComment"
      class="mobile-modal__button-wrapper">
      <div class="mobile-modal__button-item">
        <call-button
          type="warning"
          plain
          full
          :icon="dataIconClock"
          @click="dataShowAddSchedule = true;">
          Sсhedule
        </call-button>
        <call-button
          type="success"
          plain
          full
          :icon="dataIconCheck"
          @click="dataShowCloseTask = true;">
          Close
        </call-button>
      </div>
      <div class="mobile-modal__button-item">
        <call-button
          :disabled="!dataTaskDetail.sms_available"
          type="success"
          plain
          full
          :icon="dataIconSms"
          @click="clickSms()">
          SMS
        </call-button>
        <call-button
          :disabled="!dataTaskDetail.available_bonus"
          type="success"
          plain
          full
          :icon="dataIconGift"
          @click="dataShowGift = true">
          Gift
        </call-button>
        <call-button
          v-if="computedIsCall"
          type="success"
          full
          :icon="dataIconPhone"
          @click="clickCall()">
          Call Back
        </call-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TASK_ADD_COMMENT,
  TASK_PHONE_CLIENT,
  TASKS_GET,
  TASKS_GET_ALL, TASKS_UPDATE_ITEM_MOBILE,
  USER_ME
} from '../../../store/types/actions.types';
import { mapGetters } from 'vuex';
import {
  faCheck,
  faClock,
  faCommentMedical,
  faFire,
  faGift,
  faPhone, faSms,
  faTimes,
  faUser,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import CallMobileTaskCard from '../../../components/mobile/cards/CallMobileTasksCard';
import CallTimeLine from '../../../components/common/CallTimeline';
import CallButton from '../../../components/common/CallButton';
import helper from '../../../service/helper';
import { Notify, PullRefresh, Toast } from 'vant';
import CallMobileReSchedule from '../../../components/mobile/modal/CallMobileReSchedule';
import CallMobileCloseTask from '../../../components/mobile/modal/CallMobileCloseTask';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { BFormTextarea } from 'bootstrap-vue';
import eventBus from '../../../service/eventBus';
import CallDeleteComment from '../../../components/common/modals/CallDeleteComment';
import CallEditComment from '../../../components/common/modals/CallEditComment';
import { SET_ALL } from '../../../store/types/mutations.types';
import CallMobileSmsTemplate from '@/components/mobile/modal/CallMobileSmsTemplate.vue';
import CallMobileResultPhoneCall from '../../../components/mobile/modal/CallMobileResultPhoneCall';
import storage from '../../../service/storage';
import CallMobileGifts from '../../../components/mobile/modal/CallMobileGifts';
import CallMobileAddPhoneIssue from '@/components/mobile/modal/CallMobileAddPhoneIssue';
import CallMobileEditPhoneIssue from '@/components/mobile/modal/CallMobileEditPhoneIssue';

export default {
  name: 'PageTasksDetailMobile',
  components: { CallMobileEditPhoneIssue, CallMobileAddPhoneIssue, CallMobileGifts, CallMobileResultPhoneCall, CallMobileSmsTemplate, CallEditComment, CallDeleteComment, CallMobileCloseTask, CallMobileReSchedule, CallButton, CallTimeLine, CallMobileTaskCard, PullRefresh, BFormTextarea },
  mixins: [validationMixin],
  data () {
    return {
      dataIconBriefcase: faBriefcase,
      dataIconUser: faUser,
      dataIconGift: faGift,
      dataIconCheck: faCheck,
      dataIconClose: faTimes,
      dataIconAddComment: faCommentMedical,
      dataIconEmptyComment: faFire,
      dataIconClock: faClock,
      dataIconPhone: faPhone,
      dataIconSms: faSms,
      dataTaskDetail: {},
      dataIsLoading: false,
      dataShowAddIssue: false,
      dataShowEditIssue: false,
      dataPropsEditIssue: {},
      dataShowAddSchedule: false,
      dataShowAddComment: false,
      dataShowCloseTask: false,
      dataShowDeleteComment: false,
      dataShowEditComment: false,
      dataShowSmsTemplate: false,
      dataShowResultCall: false,
      dataShowGift: false,
      dataPropsComment: {},
      dataPropsCallId: null,
      dataIdSetInterval: null,
      dataNextPageOptions: {},
      dataForm: {
        comment: null
      },
      dataIsCall: true,
      dataCallDelayTimeout: null,
      dataCallIdSubmite: null
    };
  },
  computed: {
    ...mapGetters({
      computedStoreCurrentUser: 'auth/currentUser',
      storeBlockedCall: 'mobile/blockedCall'
    }),
    computedCallIdSubmite () {
      const { callId = null } = storage.get('blocked_call') ?? {};
      if (helper.isEmpty(this.dataCallIdSubmite)) {
        return helper.isEmpty(callId) ? this.computedStoreCurrentUser.blocking_call_id : callId;
      } else {
        return this.dataCallIdSubmite;
      }
    },
    computedProjectName () {
      return this.$route.query?.projectName;
    },
    computedProjectId () {
      return this.$route.params?.projectId;
    },
    computedTaskId () {
      return +this.$route.params?.taskId;
    },
    computedTaskType () {
      return this.$route.query?.type;
    },
    computedEvents () {
      return !helper.isEmpty(this.dataTaskDetail, 'events');
    },
    computedIsCall () {
      return !this.dataIsCall && !this.dataTaskDetail.call_locked;
    },
    computedTeamsIds () {
      return this.computedStoreCurrentUser.teams.map(item => item.id);
    },
    computedIsCallManual () {
      return helper.getOneArray(this.computedStoreCurrentUser.teams)?.call_mode === 'manual';
    }
  },
  validations: {
    dataForm: {
      comment: {
        required
      }
    }
  },
  deactivated () {
    this.$_stopAjaxStatusCheck();
    this.$store.commit(`mobile/${SET_ALL}`, { data: false, key: 'nextButton' });
    clearTimeout(this.dataCallDelayTimeout);
    this.$destroy();
  },
  async created () {
    await this.$_ajax();
    this.getProjectName();
    this.$_startAjaxStatusCheck();

    this.$root.$on('go-next', _ => {
      this.$router.replace({
        name: 'ProjectTasksDetail',
        params: {
          taskId: this.dataNextPageOptions.taskId,
          projectId: this.dataNextPageOptions.projectId
        },
        query: {
          ...(this.$route.query || {}),
          name: 'ID: ' + this.dataNextPageOptions.playerId
        }
      }, () => {
        this.$store.commit(`mobile/${SET_ALL}`, { data: false, key: 'nextButton' });
        this.$_ajax();
      });
    });
    this.$_methodDelayCall();

    if (!helper.isEmpty(this.storeBlockedCall)) {
      this.dataShowResultCall = true;
    }
  },
  methods: {
    getProjectName () {
      this.$set(this.$route.meta, 'taskName', `ID: ${this.dataTaskDetail.player_id}`);
      eventBus.$emit('reranderHeader');
    },
    $_methodDelayCall () {
      this.dataIsCall = this.computedStoreCurrentUser.call_locked;
      const unWatch = this.$watch('computedStoreCurrentUser.call_locked', (value) => {
        if (!value) {
          unWatch();
          console.log('delay start');
          this.dataIsCall = true;
          this.dataCallDelayTimeout = setTimeout(_ => {
            console.log('delay');
            this.$_methodDelayCall();
          }, 7000);
        }
      });
    },
    async $_ajax (toast = true) {
      const [{ task = {} }] = await Promise.all([this.$store.dispatch(`task/${TASKS_GET}`, {
        params: {
          id: this.computedTaskId
        }
      }),
      this.$store.dispatch(`auth/${USER_ME}`, {
        hotReload: true
      })]);
      this.dataTaskDetail = task;

      if (this.computedTaskType === 'free' &&
                (!helper.isEmpty(this.dataTaskDetail, 'operator_id') || !helper.isEmpty(this.dataTaskDetail, 'closed_by'))) {
        this.$_nextFreeTask();
      }

      if (this.dataIsLoading && toast) {
        Toast('Refresh Success');
        this.dataIsLoading = false;
      }
    },
    onAddIssue ({ id: callId }) {
      this.dataPropsCallId = callId;
      this.dataShowAddIssue = true;
    },
    async onGoTo () {
      await this.$_ajax(false);
      await this.$store.dispatch(`task/${TASKS_UPDATE_ITEM_MOBILE}`, {
        itemUpdate: this.dataTaskDetail
      });
      this.$router.go(-1);
    },
    onCallCustomSubmit () {
      this.dataCallIdSubmite = null;
      if (['own', 'scheduled'].includes(this.computedTaskType)) {
        this.$_nextFreeTask();
      }
    },
    async onCloseTask () {
      await this.$_ajax(false);
      await this.$store.dispatch(`task/${TASKS_UPDATE_ITEM_MOBILE}`, {
        itemUpdate: this.dataTaskDetail,
        isClosed: true
      });
    },
    async $_nextFreeTask () {
      let { payload: { list = [] } } = await this.$store.dispatch(`task/${TASKS_GET_ALL}`, {
        params: {
          type: this.computedTaskType,
          project_ids: [this.computedProjectId],
          limit: 10,
          page: 0
        }
      });

      if (~['own', 'scheduled'].indexOf(this.computedTaskType)) {
        let _sessionStorage;
        try {
          _sessionStorage = JSON.parse(sessionStorage.getItem('call-next-task'));
        } catch (e) {
          _sessionStorage = [];
        }

        _sessionStorage.push(this.computedTaskId);

        sessionStorage.setItem('call-next-task', JSON.stringify(_sessionStorage));

        list = list.filter(item => !~_sessionStorage.indexOf(item.id));
      }

      const _nextTask = list.shift();

      if (!helper.isEmpty(_nextTask)) {
        this.dataNextPageOptions = {
          projectId: this.computedProjectId,
          taskId: _nextTask.id,
          playerId: _nextTask.player_id
        };

        this.$nextTick(_ => {
          this.$store.commit(`mobile/${SET_ALL}`, { data: true, key: 'nextButton' });
        });
      }
    },
    async clickAddComment () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`task/${TASK_ADD_COMMENT}`, {
          taskId: this.computedTaskId,
          params: {
            ...this.dataForm
          },
          context: this
        });
        await this.$_ajax();
        this.dataForm.comment = null;
        this.dataShowAddComment = false;
        Notify({ type: 'success', message: 'Comment added successfully' });
      } catch (e) {
        throw Error(e);
      }
    },
    async clickCall () {
      if (this.computedIsCallManual) {
        try {
          const { id: callId = null } = await this.$store.dispatch(`task/${TASK_PHONE_CLIENT}`, {
            taskId: this.computedTaskId
          });
          this.dataShowResultCall = true;
          this.dataCallIdSubmite = callId;
          storage.set('blocked_call', {
            path: this.$route.fullPath,
            blocked: true,
            callId
          });
          window.open(`tel:${this.dataTaskDetail.player_phone}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        const toast1 = Toast.loading({
          message: 'Call in progress...',
          forbidClick: true,
          duration: 9000,
          onClose: () => {
            if (~['own', 'scheduled'].indexOf(this.computedTaskType)) {
              this.$_nextFreeTask();
            }
          }
        });
        try {
          await this.$store.dispatch(`task/${TASK_PHONE_CLIENT}`, {
            taskId: this.computedTaskId
          });
        } catch (e) {
          toast1.clear();
        }
      }
    },
    async clickSms () {
      this.dataShowSmsTemplate = true;
    },
    $_startAjaxStatusCheck () {
      this.dataIdSetInterval = setInterval(_ => {
        this.$_ajax(false);
      }, 5000);
    },
    $_stopAjaxStatusCheck () {
      clearInterval(this.dataIdSetInterval);
      this.dataIdSetInterval = null;
    },
    onActionButtonComment (data) {
      if (data.call_id) {
        this.dataPropsEditIssue = data;
        switch (data.type) {
          case 'edit':
            this.dataShowEditIssue = true;
            break;
          case 'delete':
            this.dataShowDeleteComment = true;
            break;
        }
      } else {
        this.dataPropsComment = data;
        switch (data.type) {
          case 'edit':
            this.dataShowEditComment = true;
            break;
          case 'delete':
            this.dataShowDeleteComment = true;
            break;
        }
      }
    }
  }
};
</script>
